body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;


  --wpp-font-family:"Inter", "Roboto", sans-serif !important;
  
  --wpp-typography-s-midi-font-family:"Inter", "Roboto", sans-serif !important;
  --wpp-typography-l-midi-font-family:"Inter", "Roboto", sans-serif !important;
  --wpp-typography-xl-midi-font-family:"Inter", "Roboto", sans-serif !important;
  --wpp-typography-2xl-heading-font-family: "Inter", "Roboto", sans-serif !important;
    --wpp-typography-2xs-strong-font-family: "Inter", "Roboto", sans-serif !important;
    --wpp-typography-3xl-heading-font-family: "Inter", "Roboto", sans-serif !important;
    --wpp-typography-4xl-display-font-family: "Inter", "Roboto", sans-serif !important;
    --wpp-typography-5xl-display-font-family: "Inter", "Roboto", sans-serif !important;
    --wpp-typography-l-body-font-family: "Inter", "Roboto", sans-serif !important;
    --wpp-typography-l-midi-font-family: "Inter", "Roboto", sans-serif !important;
    --wpp-typography-l-strong-font-family: "Inter", "Roboto", sans-serif !important;
    --wpp-typography-m-body-font-family: "Inter", "Roboto", sans-serif !important;
    --wpp-typography-m-body-accent-font-family:"Inter", "Roboto", sans-serif !important;
    
    --wpp-typography-m-midi-font-family: "Inter", "Roboto", sans-serif !important;
    
    --wpp-typography-m-strong-font-family: "Inter", "Roboto", sans-serif !important;
   
    --wpp-typography-s-body-font-family: "Inter", "Roboto", sans-serif !important;
    --wpp-typography-m-body-accent-font-weight: 1000 !important ;
    --wpp-typography-m-body-accent-font-size: 19px !important;
    --wpp-typography-m-body-accent-font-color :#121619 !important;
    
    --wpp-typography-s-midi-font-family: "Inter", "Roboto", sans-serif !important;
    
    --wpp-typography-s-strong-font-family: "Inter", "Roboto", sans-serif !important;
   
    --wpp-typography-xl-heading-font-family: "Inter", "Roboto", sans-serif !important;
    
    --wpp-typography-xs-body-font-family: "Inter", "Roboto", sans-serif !important;
   
    --wpp-typography-xs-midi-font-family: "Inter", "Roboto", sans-serif !important;
   
    --wpp-typography-xs-strong-font-family: "Inter", "Roboto", sans-serif !important;
    --wpp-topbar-offset-top: opx;
    --button-primary-bg-color:#5e00b5;
    --wpp-brand-color:#5e00b5;
    --wpp-brand-color-hover:#8508e8;
    --typography-font-weight:500;


    --wpp-avatar-generated-bg-color:#eab308 !important;
    --wpp-default-type-font-weight: 500 
    --wpp-typography-l-body-font-weight: 1000 !important  ;
    --wpp-typography-l-body-font-size: 19px ;
    --wpp-default-type-font-weight: "bold" !important;
    --wpp-dataviz-color-cat-neutral-3:none;
    --wpp-avatar-generated-bg-color:#eab308 !important;
    --typography-font-weight:"1000" !important;
    /* --wpp-typography-font-weight:500  */
   
  /* --wpp-default-type-font-family:"Inter", "Roboto", sans-serif !important;
  --wpp-font-family:"Inter", "Roboto", sans-serif !important;
  --typography-font-family:"Inter", "Roboto", sans-serif !important; */
  height: 100vh;
}

#root {
  height: 100%;
}

.ag-root-wrapper {
  border-radius: 4px;
}

